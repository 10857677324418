<template>
  <AuthSearchLayout>
    <template v-slot:header>
      <p class="authoriz__text-hint">Уже зарегистрированы?</p>
      <router-link
        :to="{ name: 'Login' }"
        class="btn btn_color_white btn-login_white"
      >
        Войти
      </router-link>
    </template>
    <template v-slot:auth-title>
      <p class="authoriz__title-text flex-left">Регистрация</p>
    </template>
    <template v-slot:default>
      <slot></slot>
    </template>
  </AuthSearchLayout>
</template>

<script>
import AuthSearchLayout from "@/components/layouts/AuthSearchLayout";
export default {
  name: "RegistrationSearchLayout",
  components: { AuthSearchLayout }
};
</script>

<style scoped></style>
