<template>
  <RegistrationSearchLayout>
    <template v-slot:default>
      <ValidationInputField
        label="Введите адрес, название объекта"
        v-model="searchObject"
        required
        validate-name="Адрес"
        rules="required"
        @input="searchObjectAddress"
      />

      <div class="items-register-address">
        <!--        <p class="item-register-address">ул. Волкова, д. 7/29</p>-->
        <div v-for="n in 7" :key="n">
          <p
            v-if="objectAddresses.results[n - 1]"
            @click="selectObject(objectAddresses.results[n - 1].id)"
            class="item-register-address"
          >
            {{ objectAddresses.results[n - 1].address }}
            {{ objectAddresses.results[n - 1].id }}
          </p>
          <div v-else style="height: 42px;"></div>
        </div>
      </div>
    </template>
  </RegistrationSearchLayout>
</template>

<script>
import RegistrationSearchLayout from "@/components/Auths/Registration/RegistrationSearchLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
export default {
  name: "RegistrationObjectForm",
  components: { ValidationInputField, RegistrationSearchLayout },
  computed: {
    city() {
      return this.$store.getters.getRegistrationForm.city.value;
    },
    objectAddresses() {
      return this.$store.getters.getObjectsAddress;
    },
    searchObject: {
      get() {
        return this.$store.getters.getRegistrationForm.searchObject.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "searchObject",
          value: newValue
        });
      }
    }
  },
  methods: {
    searchObjectAddress() {
      this.$store.dispatch("searchObjectAddress", {
        city: this.city,
        search: this.searchObject
      });
    },
    selectObject(id) {
      this.$store.dispatch("selectObjectAddress", { id: id });
    }
  }
};
</script>

<style scoped></style>
