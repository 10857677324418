<template>
  <AuthLayout :diz-robot="dizRobot">
    <template v-slot:header>
      <p class="authoriz__text-hint">Уже зарегистрированы?</p>
      <router-link
        :to="{ name: 'Login' }"
        class="btn btn_color_white btn-login_white"
      >
        Войти
      </router-link>
    </template>
    <template v-slot:auth-title>
      <p class="authoriz__title-text flex-left">Регистрация</p>
    </template>
    <template v-slot:info-text>
      <slot name="info-text"></slot>
    </template>
    <template v-slot:auth-inputs>
      <slot name="auth-inputs"></slot>
    </template>
    <template v-slot:action>
      <slot name="action"></slot>
    </template>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/layouts/AuthLayout";
export default {
  name: "RegistrationLayout",
  components: { AuthLayout },
  props: {
    dizRobot: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
