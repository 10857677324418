<template>
  <div class="captcha-container">
    <div class="d-flex align-center">
      <img :src="captchaImage" alt="captcha" style="width: 75%; height: 75%" />
      <button
        @click="refreshCaptcha"
        class="btn btn_color_green btn-change"
      ></button>
    </div>

    <div class="captcha-input-container">
      <ValidationInputField
        :rules="'min:1|max:4'"
        v-model="captchaInput"
        type="text"
        placeholder="Введите код с изображения"
        label="Код с изображения"
      />
    </div>
  </div>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";

export default {
  data() {
    return {};
  },
  components: {
    ValidationInputField
  },
  computed: {
    captchaImage() {
      return this.$store.getters.getCaptcha;
    },
    captchaInput: {
      get() {
        return this.$store.getters.getCaptchaValue.value;
      },
      set(newValue) {
        this.$store.commit("setCaptchaValue", {
          field: "captcha",
          value: newValue
        });
      }
    }
  },
  created() {
    this.fetchCaptchaOnMount();
  },
  methods: {
    async fetchCaptchaOnMount() {
      await this.refreshCaptcha();
    },
    async refreshCaptcha() {
      try {
        await this.$store.dispatch("fetchCaptcha");
      } catch (error) {
        console.error("Ошибка при запросе капчи:", error);
      }
    }
  }
};
</script>

<style scoped>
.captcha-container {
  align-items: center;
  justify-content: center;
}

.captcha-input-container {
  margin-top: 1rem;
  width: 50%;
}

img {
}

button {
  margin-left: 1rem;
}
</style>
