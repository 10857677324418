<template>
  <ValidationForm v-slot:default>
    <div
      class=""
      v-if="isDateLoaded"
      style="height: 100vh; width:100%;background: rgba(0,0,0,0.2); z-index: 10; position: absolute; backdrop-filter: blur(3px)"
    >
      <div class="d-flex align-center justify-center" style="height: 100vh">
        <v-progress-circular indeterminate size="78" />
      </div>
    </div>
    <AuthLayout>
      <template v-slot:header>
        <p class="authoriz__text-hint">Новый пользователь?</p>

        <router-link
          :to="{ name: 'StartRegistration' }"
          class="btn btn_color_white btn-check"
        >
          Регистрация
        </router-link>
      </template>
      <template v-slot:auth-title>
        <p class="authoriz__title-text flex-left">Войти</p>
      </template>
      <template v-slot:auth-inputs>
        <ValidationInputField
          type="tel"
          label="Телефон"
          v-model="phone"
          mask="+7 (9##) ###-##-##"
          required
          rules="required"
          style="margin-bottom: 20px"
        />
        <div class="relative">
          <div class="input-wrapper">
            <ValidationInputField
              :type="typePwd"
              label="Пароль"
              v-model="password"
              required
              rules="required"
              id="password"
              style="width: 100%"
            />
            <div
              v-if="typePwd == 'password'"
              class="input-icon"
              @click="togglePassword"
            >
              <img
                src="/img/icons/btn-eye_icon.svg"
                class="no-select"
                style="pointer-events: none;width: 20px; height: 20px"
                alt="Показать пароль"
                title="Показать пароль"
              />
            </div>
            <div v-else class="input-icon" @click="togglePassword">
              <img
                src="/img/icons/closed-eye.svg"
                class="no-select"
                style="width: 20px; height: 20px"
                alt="Скрыть пароль"
                title="Скрыть пароль"
              />
            </div>
          </div>

          <router-link
            :to="{ name: 'StartRecovery' }"
            class="authoriz__link authoriz__link-input"
          >
            Восстановление пароля
          </router-link>
        </div>
      </template>

      <template v-slot:action>
        <v-btn
          type="submit"
          @click="login"
          class="btn btn_color_green btn-login flex-right authoriz__btn"
        >
          Войти
        </v-btn>
      </template>
    </AuthLayout>
  </ValidationForm>
</template>

<script>
import AuthLayout from "@/components/layouts/AuthLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "LoginForm",
  components: { ValidationForm, ValidationInputField, AuthLayout },
  data() {
    return {
      isDateLoaded: true,
      typePwd: "password"
    };
  },
  computed: {
    phone: {
      get() {
        return this.$store.getters.getLoginForm.phone.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "loginForm",
          field: "phone",
          value: newValue
        });
      }
    },

    password: {
      get() {
        return this.$store.getters.getLoginForm.password.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "loginForm",
          field: "password",
          value: newValue
        });
      }
    }
  },
  mounted() {
    setTimeout(() => {
      const els = document.querySelectorAll("input:-webkit-autofill")
      els.forEach((el) => {
        const label = el.parentElement.querySelector("label")
        label.classList.add("v-label--active")
        label.classList.add("primary--text")
      })
    }, 1000)
  },
  methods: {
    login() {
      this.$store.dispatch("login");
    },
    togglePassword() {
      if (this.typePwd === "password") {
        this.typePwd = this.typePwd === "password" ? "text" : "password";
      } else {
        this.typePwd = this.typePwd === "password" ? "text" : "password";
      }
    }
  },
  created() {
    setTimeout(() => (this.isDateLoaded = false), 1000);
  }

};
</script>

<style scoped>
.input-wrapper {
  display: flex;
  align-items: baseline;
}
.input-icon {
  cursor: pointer;
  transition: all 1s ease;
  padding: 10px 15px;
  position: absolute;
  left: 190px;
}
@media screen and (max-width: 1000px) {
  .input-icon {
    left: 160px;
  }
}
@media screen and (max-width: 520px) {
  .input-icon {
    left: 130px;
  }
}
@media screen and (max-width: 450px) {
  .input-icon {
    left: 110px;
  }
}
@media screen and (max-width: 410px) {
  .input-icon {
    left: 90px;
  }
}
</style>
