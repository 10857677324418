<template
  ><ValidationForm v-slot:default="{ handleSubmit }">
    <RegistrationLayout diz-robot>
      <template v-slot:info-text>
        <p class="authoriz__register-info-text">
          Введите ID для получения доступа
        </p>
      </template>
      <template
        class="authoriz__inputs authoriz__inputs-register-id"
        v-slot:auth-inputs
      >
        <ValidationInputField
          label="ID"
          v-model="account"
          required
          mask="#### #### #### ####"
          rules="required"
        />

        <div class="flex-ver-center relative">
          <p class="authoriz__text-input authoriz__text-input-register">
            Введите 16 цифр: 0000 0000 0000 0000
          </p>
        </div>
      </template>
      <template v-slot:action>
        <button @click="backToObject" class="btn btn_color_white authoriz__btn">
          Отмена
        </button>
        <v-btn
          type="submit"
          @click="handleSubmit(checkAccount)"
          class="btn btn_color_green flex-right authoriz__btn"
          >Далее</v-btn
        >
      </template>
    </RegistrationLayout>
  </ValidationForm>
</template>

<script>
import RegistrationLayout from "@/components/Auths/Registration/RegistrationLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "RegistrationAccountForm",
  components: { ValidationForm, ValidationInputField, RegistrationLayout },
  computed: {
    account: {
      get() {
        return this.$store.getters.getRegistrationForm.account.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "account",
          value: newValue
        });
      }
    }
  },
  methods: {
    checkAccount() {
      this.$store.dispatch("checkAccount");
    },
    backToObject() {
      this.$router.push({ name: "ObjectRegistration" });
    }
  }
};
</script>

<style scoped></style>
