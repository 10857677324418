<template>
  <RegistrationPhoneCheckForm />
</template>

<script>
import RegistrationPhoneCheckForm from "@/components/Auths/Registration/RegistrationPhoneCheckForm";
export default {
  name: "RegistrationPhoneCheckPage",
  components: { RegistrationPhoneCheckForm }
};
</script>

<style scoped></style>
