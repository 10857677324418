import { render, staticRenderFns } from "./RecoveryLayout.vue?vue&type=template&id=0a3aa044&scoped=true&"
import script from "./RecoveryLayout.vue?vue&type=script&lang=js&"
export * from "./RecoveryLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3aa044",
  null
  
)

export default component.exports