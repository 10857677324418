<template>
  <AuthLayout>
    <template v-slot:header>
      <p class="authoriz__text-hint">Новый пользователь?</p>
      <router-link
        :to="{ name: 'StartRegistration' }"
        class="btn btn_color_white btn-check"
      >
        Регистрация
      </router-link>
    </template>
    <template v-slot:auth-title>
      <p class="authoriz__title-text flex-left">Восстановление пароля</p>
      <p class="authoriz__text-hint authoriz__text-hint-pass">
        Вспомнили пароль?
      </p>
      <router-link tag="button" :to="{ name: 'Login' }" class="authoriz__link">
        Войти
      </router-link>
    </template>

    <template v-slot:auth-inputs>
      <slot name="auth-inputs"></slot>
    </template>
    <template v-slot:action>
      <slot name="action"></slot>
    </template>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/layouts/AuthLayout";
export default {
  name: "RecoveryLayout",
  components: { AuthLayout }
};
</script>

<style scoped></style>
