import { render, staticRenderFns } from "./RegistrationCityForm.vue?vue&type=template&id=5bfffc16&scoped=true&"
import script from "./RegistrationCityForm.vue?vue&type=script&lang=js&"
export * from "./RegistrationCityForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfffc16",
  null
  
)

export default component.exports