<template>
  <RegistrationSearchLayout>
    <template v-slot:default>
      <ValidationInputField
        label="Город"
        v-model="searchCity"
        required
        validate-name="Город"
        rules="required"
        @input="searchCities"
      />
      <!--      TODO если городов станет много дописать метод для поиска по букве -->
      <div v-if="false" class="btns-wrap-register-city">
        <div class="btns-register-city">
          <div class="btns-inner-register-city">
            <p class="btn-register-city active">A</p>
            <p class="btn-register-city">Б</p>
            <p class="btn-register-city">В</p>
            <p class="btn-register-city">Д</p>
            <p class="btn-register-city">З</p>
            <p class="btn-register-city">И</p>
            <p class="btn-register-city">К</p>
            <p class="btn-register-city">Л</p>
            <p class="btn-register-city">М</p>
            <p class="btn-register-city">Н</p>
            <p class="btn-register-city">Р</p>
            <p class="btn-register-city">Т</p>
          </div>
        </div>
        <div class="btns-register-city">
          <div class="btns-inner-register-city">
            <p class="btn-register-city">У</p>
            <p class="btn-register-city">Ч</p>
          </div>
          <div class="points-register-city">
            <p class="point-register-city">Казань</p>
            <p class="point-register-city">Набережные челны</p>
          </div>
        </div>
      </div>
      <div class="items-register-city mt-4">
        <p
          @click="selectCity(city.id)"
          v-for="city in cities.results.slice(0, 30)"
          :key="city.id"
          class="item-register-city"
        >
          {{ city.name }}
        </p>
      </div>
    </template>
  </RegistrationSearchLayout>
</template>

<script>
import RegistrationSearchLayout from "@/components/Auths/Registration/RegistrationSearchLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
export default {
  name: "RegistrationCityForm",
  components: { ValidationInputField, RegistrationSearchLayout },
  computed: {
    cities() {
      return this.$store.getters.getCities;
    },
    searchCity: {
      get() {
        return this.$store.getters.getRegistrationForm.searchCity.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "searchCity",
          value: newValue
        });
      }
    }
  },
  methods: {
    searchCities() {
      this.$store.dispatch("searchCities", { search: this.searchCity });
    },
    selectCity(id) {
      this.$store.dispatch("selectCity", { id: id });
    }
  }
};
</script>

<style scoped></style>
