<template>
  <LoginForm />
</template>

<script>
import LoginForm from "@/components/Auths/Login/LoginForm";
export default {
  name: "LoginPage",
  components: { LoginForm },
  mounted() {
    if (this.$store.getters.isAuthorized) {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped></style>
