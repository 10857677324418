<template>
  <RegistrationFinishForm />
</template>

<script>
import RegistrationFinishForm from "@/components/Auths/Registration/RegistrationFinishForm";
export default {
  name: "RegistrationFinishPage",
  components: { RegistrationFinishForm }
};
</script>

<style scoped></style>
