<template>
  <RecoveryStartForm />
</template>

<script>
import RecoveryStartForm from "@/components/Auths/Recovery/RecoveryStartForm";
export default {
  name: "RecoveryStartPage",
  components: { RecoveryStartForm }
};
</script>

<style scoped></style>
