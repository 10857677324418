<template>
  <RegistrationObjectForm />
</template>

<script>
import RegistrationObjectForm from "@/components/Auths/Registration/RegistrationObjectForm";
export default {
  name: "RegistrationObjectPage",
  components: { RegistrationObjectForm },
  computed: {
    city() {
      return this.$store.getters.getRegistrationForm.city.value;
    }
  },
  created() {
    this.$store.dispatch("searchObjectAddress", { city: this.city });
  }
};
</script>

<style scoped></style>
