<template>
  <RegistrationForm />
</template>

<script>
import RegistrationForm from "@/components/Auths/Registration/RegistrationStartForm";
export default {
  name: "RegistrationPage",
  components: { RegistrationForm }
};
</script>

<style scoped></style>
