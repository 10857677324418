<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RegistrationLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          type="tel"
          label="телефон"
          v-model="phone"
          mask="+7 (9##) ###-##-##"
          required
          rules="required|phone"
        />

        <div class="flex-ver-center">
          <p
            v-if="phone.length < 18"
            class="authoriz__text-input authoriz__text-input-recovery"
          >
            Введите номер в формате:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >
            для получение потверждающего звонка.
          </p>
          <p v-else class="authoriz__text-input authoriz__text-input-recovery">
            На номер телефона:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >
            <span v-else style="font-weight: 600">{{ phone }}</span> сейчас
            поступит СМС. Введите последние 4 цифры.
          </p>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          @click="handleSubmit(registrationByAcc)"
          class="btn btn_color_green flex-right authoriz__btn"
          :disabled="phone.length < 18"
        >
          Далее
        </v-btn>
      </template>
    </RegistrationLayout>
  </ValidationForm>
</template>

<script>
import RegistrationLayout from "@/components/Auths/Registration/RegistrationLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "RegistrationPhoneCheckForm",
  components: { ValidationForm, ValidationInputField, RegistrationLayout },
  computed: {
    phone: {
      get() {
        return this.$store.getters.getRegistrationForm.phone.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "phone",
          value: newValue
        });
      }
    }
  },
  methods: {
    registrationByAcc() {
      this.$store.dispatch("registrationByAccount");
    }
  }
};
</script>

<style scoped></style>
