<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RecoveryLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          label="Новый пароль"
          type="password"
          v-model="password"
          required
          rules="required"
        />

        <div class="flex-ver-center">
          <p class="authoriz__text-input authoriz__text-input-recovery-pass">
            Придумайте <span>новый пароль</span> для входа
          </p>
        </div>
        <ValidationInputField
          label="Пароль повторно"
          type="password"
          v-model="repeatPassword"
          required
          rules="required"
        />
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          @click="handleSubmit(finishRecovery)"
          class="btn btn_color_green flex-right authoriz__btn"
        >
          Готово
        </v-btn>
      </template>
    </RecoveryLayout>
  </ValidationForm>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import RecoveryLayout from "@/components/Auths/Recovery/RecoveryLayout";
import ValidationForm from "@/components/ValidationField/ValidationForm";

export default {
  name: "RecoveryFinishForm",
  components: { ValidationForm, RecoveryLayout, ValidationInputField },
  computed: {
    password: {
      get() {
        return this.$store.getters.getRecoveryForm.password.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "recoveryForm",
          field: "password",
          value: newValue
        });
      }
    },
    repeatPassword: {
      get() {
        return this.$store.getters.getRecoveryForm.repeatPassword.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "recoveryForm",
          field: "repeatPassword",
          value: newValue
        });
      }
    }
  },
  methods: {
    finishRecovery() {
      this.$store.dispatch("finishRecovery");
    }
  }
};
</script>

<style scoped></style>
