<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RecoveryLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          v-if="!sendSMS"
          label="4 цифры входящего номера"
          v-model="code"
          validate-name="входящего номера"
          mask="####"
          rules="required"
        />
        <ValidationInputField
          v-else
          label="Код подтверждения"
          v-model="code"
          validate-name="Код подтверждения"
          mask="####"
          rules="required"
        />
        <div class="flex-ver-center">
          <p
            v-if="phone.length < 18"
            class="authoriz__text-input authoriz__text-input-recovery"
          >
            Введите номер в формате:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >

            <span class="span">для получение потверждающего смс.</span>
          </p>
          <p v-else class="authoriz__text-input authoriz__text-input-recovery">
            На номер телефона:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >
            <span v-else style="font-weight: 600">{{ phone }}</span>

            <span> направлено СМС с кодом подтверждения</span>
          </p>
        </div>
        <router-link :to="{ name: 'StartRecovery' }" class="btn btn_color_white"
          >Изменить номер</router-link
        >
        <div class="flex-ver-center">
          <p
            v-if="codeExp"
            class="authoriz__text-input"
            style="font-size: 14px"
          >
            Повторить отправку<br />
            через
            <span class="timer">{{ codeExp }}</span>
          </p>
          <v-btn
            v-if="sendSMS && !codeExp"
            type="submit"
            @click="startRecovery"
            class="btn btn_color_green flex-right authoriz__btn btn-change"
            style="width: 100%"
          >
            Отправить СМС
          </v-btn>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          @click="handleSubmit(checkCode)"
          class="btn btn_color_green flex-right authoriz__btn warning warning-code"
        >
          Подтвердить
        </v-btn>
      </template>
    </RecoveryLayout>
  </ValidationForm>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import moment from "moment";
import RecoveryLayout from "@/components/Auths/Recovery/RecoveryLayout";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "RecoveryCodeForm",
  components: { ValidationForm, RecoveryLayout, ValidationInputField },
  data() {
    return {
      codeExp: false,
      timer: null,
      sendSMS: false
    };
  },
  destroyed() {
    this.stopTimer();
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    phone() {
      return this.$store.getters.getRecoveryForm.phone.value;
    },
    code: {
      get() {
        return this.$store.getters.getRecoveryForm.code.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "recoveryForm",
          field: "code",
          value: newValue
        });
      }
    },
    temp_token() {
      return this.$store.getters.getTempToken;
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.codeExp = this.formatTime();
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.sendSMS = true;
    },
    formatTime() {
      const timeDiff =
        (this.temp_token ? this.temp_token.repeat : 0) * 1000 - Date.now();
      const timeRemain = moment(timeDiff).format("mm:ss");
      if (timeDiff <= 0) this.stopTimer();
      return timeDiff > 0 ? timeRemain : false;
    },
    checkCode() {
      this.$store.dispatch("checkRecoveryCode");
    },
    startRecovery() {
      this.$store
        .dispatch("startRecovery", { type: "sms" })
        .then(() => {
          this.sendSMS = true;
          this.startTimer();
        })
        .catch(e => {
          if (e.response.status == 401) {
            this.$store.commit("SET_ERROR_NOTIFICATION", {
              errors: [
                {
                  field_verbose: "Ошибка",
                  message: "В системе данный номер не зарегистрирован!"
                }
              ]
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.btn.warning-code:after {
  content: "Необходимо ввести 4 цифры" !important;
  white-space: break-spaces !important;
}
.btn.warning:after {
  height: 30px !important;
  bottom: -28px;
}
</style>
