<template>
  <RegistrationCityForm />
</template>

<script>
import RegistrationCityForm from "@/components/Auths/Registration/RegistrationCityForm";
export default {
  name: "RegistrationCityPage",
  components: { RegistrationCityForm },
  created() {
    this.$store.dispatch("searchCities", { search: null });
  }
};
</script>

<style scoped></style>
