<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RegistrationLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          label="Новый пароль"
          type="password"
          v-model="password"
          required
          rules="required"
        />

        <div class="flex-ver-center">
          <p class="authoriz__text-input authoriz__text-input-recovery-pass">
            Придумайте <span>новый пароль</span> для входа
          </p>
        </div>
        <ValidationInputField
          label="Пароль повторно"
          type="password"
          v-model="repeatPassword"
          required
          rules="required"
        />
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          @click="handleSubmit(finishRegistration)"
          class="btn btn_color_green flex-right authoriz__btn"
        >
          Готово
        </v-btn>
      </template>
    </RegistrationLayout>
  </ValidationForm>
</template>

<script>
import RegistrationLayout from "@/components/Auths/Registration/RegistrationLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "RegistrationFinishForm",
  components: { ValidationForm, ValidationInputField, RegistrationLayout },
  computed: {
    password: {
      get() {
        return this.$store.getters.getRegistrationForm.password.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "password",
          value: newValue
        });
      }
    },
    repeatPassword: {
      get() {
        return this.$store.getters.getRegistrationForm.repeatPassword.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "repeatPassword",
          value: newValue
        });
      }
    }
  },
  methods: {
    finishRegistration() {
      this.$store.dispatch("finishRegistration");
    }
  }
};
</script>

<style scoped></style>
