<template>
  <RecoveryFinishForm />
</template>

<script>
import RecoveryFinishForm from "@/components/Auths/Recovery/RecoveryFinishForm";
export default {
  name: "RecoveryFinishPage",
  components: { RecoveryFinishForm }
};
</script>

<style scoped></style>
