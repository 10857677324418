<template>
  <div class="body body-authoriz" style="height: 100vh !important">
    <div class="authoriz">
      <div class="authoriz__item flex-ver-center">
        <img
          src="img/images/logo.svg"
          style="width: 141px; height: 54px;"
          alt="authoriz-logo"
          class="flex-left no-select"
        />
        <div class="authoriz__text-hint-wrap">
          <slot name="header" />
        </div>
      </div>

      <div
        class="authoriz__item authoriz__item-inputs"
        style="max-height: 65vh; overflow: auto"
      >
        <div class="authoriz__title">
          <slot name="auth-title"> </slot>
        </div>
        <div>
          <slot name="info-text"> </slot>
        </div>
        <div class="authoriz__inputs ym-hide-content">
          <slot name="auth-inputs"> </slot>
        </div>
        <div
          class="authoriz__inputs"
          style="margin-top: 20px ;    display: grid;
    grid-template-columns: 1fr;"
        >
          <Captcha
            v-if="this.$route.name == 'StartRegistration' && shouldShowCaptcha"
          />
        </div>
      </div>

      <div class="authoriz__item  flex-ver-center">
        <img
          v-if="!dizRobot"
          src="img/images/authoriz-robot.png"
          style="height: 100%; z-index: 5;"
          alt="authoriz-robot"
          class="authoriz__robot no-select displayNone"
        />
        <slot name="action" />
      </div>
    </div>
    <img
      src="img/images/body-authoriz__bg-1.png"
      alt="body-authoriz__bg"
      class="body-authoriz__bg body-authoriz__bg-1 no-select"
    />
    <img
      src="img/images/body-authoriz__bg-2.png"
      alt="body-authoriz__bg"
      class="body-authoriz__bg body-authoriz__bg-2 no-select"
    />
  </div>
</template>

<script>
import Captcha from "@/components/Captcha/captcha";
export default {
  name: "AuthLayout",
  props: {
    dizRobot: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Captcha
  },
  computed: {
    shouldShowCaptcha() {
      const currentPhone = this.$store.getters.getRegistrationForm.phone.value;
      const previousPhone = this.$store.getters.getPreviousPhone; // Assume there's a getter for the previous phone in your store
      // Compare current phone with previous phone, and decide whether to show the captcha
      return currentPhone.length === 18 && currentPhone !== previousPhone;
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 650px) {
  .displayNone {
    display: none;
  }
}
</style>
