<template>
  <RecoveryCodeForm />
</template>

<script>
import RecoveryCodeForm from "@/components/Auths/Recovery/RecoveryCodeForm";
export default {
  name: "RecoveryCodePage",
  components: { RecoveryCodeForm }
};
</script>

<style scoped></style>
