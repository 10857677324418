<template>
  <RegistrationAccountForm />
</template>

<script>
import RegistrationAccountForm from "@/components/Auths/Registration/RegistrationAccountForm";
export default {
  name: "RegistrationAccountPage",
  components: { RegistrationAccountForm }
};
</script>

<style scoped></style>
