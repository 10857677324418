<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RegistrationLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          type="tel"
          label="Телефон"
          v-model="phone"
          mask="+7 (9##) ###-##-##"
          required
          rules="required|phone"
          style="margin-bottom: 20px"
        />

        <div class="relative">
          <p class="authoriz__text-input authoriz__text-input-register">
            Введите 10 цифр +7 <span>(999) 888-77-66</span>
          </p>
          <router-link
            :to="{ name: 'StartRecovery' }"
            class="authoriz__link authoriz__link-input"
          >
            Восстановление пароля
          </router-link>
        </div>

        <div class="agreement_box">
          <v-checkbox
            class="checkbox-type castom"
            v-model="agreement"
            hide-details
          ></v-checkbox>
          <div class="agreement_text">
            Я согласен на обработку
            <a
              class="authoriz__link"
              target="_blank"
              href="https://oppen.ru/soglashenie-na-obrabotku/"
              >персональных данных</a
            >
            и принимаю условия
            <a
              class="authoriz__link"
              target="_blank"
              href="https://oppen.ru/polzovatelskoe-soglashenie/"
              >Пользовательского соглашения</a
            >
            и
            <a
              class="authoriz__link"
              target="_blank"
              href="https://oppen.ru/politika-konfidentsialnosti/"
              >Политики конфиденциальности</a
            >
          </div>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          :disabled="!agreement || captcha?.length != 4"
          @click="handleSubmit(startRegistration)"
          class="btn btn_color_green flex-right authoriz__btn"
        >
          Далее
        </v-btn>
      </template>
    </RegistrationLayout>
  </ValidationForm>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import RegistrationLayout from "@/components/Auths/Registration/RegistrationLayout";
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "RegistrationForm",
  data: () => ({
    agreement: false
  }),
  components: { ValidationForm, RegistrationLayout, ValidationInputField },
  computed: {
    captcha: {
      get() {
        return this.$store.getters.getCaptchaValue.value;
      }
    },
    phone: {
      get() {
        return this.$store.getters.getRegistrationForm.phone.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "registrationForm",
          field: "phone",
          value: newValue
        });
      }
    }
  },
  methods: {
    startRegistration() {
      this.$store.dispatch("startRegistration");
    }
  },
  created() {
    this.$store.commit("setCaptchaValue", {
      field: "captcha",
      value: this.$store?.getters?.getCaptchaValue?.value
    });
    if (this.$store.getters.getLoginForm.phone.value) {
      this.$store.commit("setFormValue", {
        formName: "registrationForm",
        field: "phone",
        value: this.$store.getters.getLoginForm.phone.value
      });
    } else {
      this.$store.commit("setFormValue", {
        formName: "registrationForm",
        field: "phone",
        value: this.$store.getters.getRegistrationForm.phone.value
      });
    }
  }
};
</script>

<style scoped>
.castom {
  margin-top: 5px !important;
}
.agreement_text {
  line-height: normal;
  font-size: 14px;
}
.authoriz__link-input {
  bottom: -50px;
}
@media (min-width: 749px) {
  .agreement_box {
    display: flex;
    width: 125%;
  }
}
@media (max-width: 749px) {
  .agreement_box {
    display: flex;
    width: 150%;
    margin-top: 30px;
  }
  .authoriz__link-input {
    bottom: -40px;
  }
}
</style>
