<template>
  <div class="body body-authoriz" style="height: 100vh !important;">
    <div class="authoriz">
      <div class="authoriz__item flex-ver-center">
        <img
          src="img/images/logo.svg"
          style="width: 141px; height: 54px;"
          alt="authoriz-logo"
          class="flex-left no-select"
        />
        <div class="authoriz__text-hint-wrap">
          <slot name="header" />
        </div>
      </div>

      <div class="authoriz__item authoriz__item-inputs">
        <div class="authoriz__title">
          <slot name="auth-title"> </slot>
        </div>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
    <img
      src="img/images/body-authoriz__bg-1.png"
      alt="body-authoriz__bg"
      class="body-authoriz__bg body-authoriz__bg-1 no-select"
    />
    <img
      src="img/images/body-authoriz__bg-2.png"
      alt="body-authoriz__bg"
      class="body-authoriz__bg body-authoriz__bg-2 no-select"
    />
  </div>
</template>

<script>
export default {
  name: "AuthSearchLayout"
};
</script>

<style scoped></style>
