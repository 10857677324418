<template>
  <RegistrationCodeForm />
</template>

<script>
import RegistrationCodeForm from "@/components/Auths/Registration/RegistrationCodeForm";
export default {
  name: "RegistrationCodePage",
  components: { RegistrationCodeForm }
};
</script>

<style scoped></style>
